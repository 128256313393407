<template>
  <v-row class="mt-5" justify="center">
    <modal-template
      @close="handleCloseDialog"
      @continue="handleSaveForm"
      :open="isFormDialogOpen"
      :primaryBtnProps="{
        text: 'Guardar',
        show: true,
      }"
      :secondaryBtnProps="{
        text: 'Cerrar',
        show: true,
        class: 'mr-2',
      }"
    >
      <template #content>
        <div class="d-flex flex-column align-stretch">
          <p class="text-h3 align-self-center">
            {{ formTitles[formMode] || "" }}
          </p>
          <v-form ref="couponForm">
            <v-text-field
              v-model="coupon.code"
              label="Código"
              filled
              rounded
              :rules="validations.code"
            />
            <v-text-field
              v-model="coupon.percentOff"
              label="Porcentaje de descuento"
              type="number"
              filled
              rounded
              :rules="validations.percentOff"
            />

            <v-col cols="12" v-if="formMode == 'CREATE'">
              <v-checkbox
                hide-details
                @change="toggleLimit"
                class="mr-4"
                v-model="acceptBill"
              >
                <template v-slot:label>
                  <span class="d-flex align-center pa-0"
                    >Colocar límite al uso de veces que se puede aplicar.</span
                  >
                </template>
              </v-checkbox>
            </v-col>

            <v-col cols="12" v-if="openLimit">
              <v-text-field
                v-model="coupon.limit"
                label="Cantidad de veces que puede ser aplicado"
                filled
                type="number"
                rounded
                :rules="validations.limit"
              />
            </v-col>

            <v-col
              cols="12"
              class="d-flex align-center"
              v-if="formMode == 'CREATE'"
            >
              <v-checkbox
                hide-details
                @change="toggleExpirationDate"
                class="mr-2 pb-4"
                v-model="expirationDate"
              >
                <template v-slot:label>
                  <span class="">Programar fecha de expiración de cupón.</span>
                </template>
              </v-checkbox>
              <v-tooltip top left offset-y max-width="350px">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" class="ml-0" dark v-on="on">
                    mdi-help-circle
                  </v-icon>
                </template>
                <span
                  >Se refiere a la fecha límite en que el cupón seguirá siendo
                  válido, pasando esta fecha, el cupón se inactiva para
                  todos.</span
                >
              </v-tooltip>
            </v-col>

            <v-col cols="12" v-if="openExpirationDate">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="initialDateFormatted"
                    label="Fecha de expiración *"
                    persistent-hint
                    readonly
                    v-on="on"
                    required
                    :rules="startDateRules"
                    filled
                    rounded
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="coupon.endDate"
                  no-title
                  @input="dateMenu = false"
                  :min="todayDate"
                  locale="es"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-form>
        </div>
      </template>
    </modal-template>
    <v-col
      cols="12"
      class="d-flex justify-end py-5"
      v-if="user.permissions.Membership && user.permissions.Membership.update"
    >
      <action-button @click="handleOpenForm('CREATE')">
        <span>
          <v-icon> mdi-plus </v-icon>
          Crear cupón
        </span>
      </action-button>
    </v-col>
    <v-col v-if="coupons.length > 0" cols="12">
      <v-data-table
        :headers="cols"
        :items="coupons"
        :loading="loading"
        hide-default-footer
        item-key="_id"
        class="elevation-1"
        v-if="coupons.length > 0"
        disable-pagination
      >
        <template #[`item.actions`]="{ item }">
          <td
            :class="
              $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
            "
          >
            <div
              :class="
                $vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-row__cell'
                  : ''
              "
              class="d-flex justify-center"
            >
              <v-btn
                icon
                color="accent"
                @click="handleOpenForm('UPDATE', item)"
                :disabled="
                  !user.permissions.Membership ||
                  !user.permissions.Membership.update
                "
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn
                icon
                color="red"
                @click="handleOpenDeleteDialog(item)"
                :disabled="
                  !user.permissions.Membership ||
                  !user.permissions.Membership.update
                "
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-else cols="12" class="d-flex flex-column align-center">
      <h2 class="my-5">No se han registrado cupones</h2>
      <v-img src="@/assets/images/general/no_data_events.svg" max-width="300" />
    </v-col>
    <modal-template
      :open="isDeleteDialogOpen"
      @close="handleCloseDeleteDialog"
      @continue="deleteCoupon"
      :primaryBtnProps="{
        text: 'Eliminar',
        show: true,
      }"
      :secondaryBtnProps="{
        text: 'Cancelar',
        show: true,
      }"
    >
      <template #content>
        <div class="d-flex flex-column align-center">
          <p class="text-h3">Eliminar Cupón</p>
          <p class="text-subtitle-1">
            ¿Estás seguro que quieres eliminar el cupón "{{ coupon.code }}"?
          </p>
        </div>
      </template>
    </modal-template>
  </v-row>
</template>
<script>
import moment from "moment";
import ModalTemplate from "@/components/shared/ModalTemplate";
import ActionButton from "@/components/shared/ActionButton";
import Endpoints from "@/share/Endpoints";
import { DefaultDateFormat } from "@/share/constants";
import { validations } from "@/share/validations";
import { commit } from "vuex";
import Vue from "vue";
import { Base64 } from "js-base64";
import { mapState, mapMutations, mapActions } from "vuex";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import path from "path";
import { get } from "http";
import axios from "axios";

export default {
  components: {
    ActionButton,
    ModalTemplate,
  },
  props: ["affiliationId"],
  data: () => ({
    openExpirationDate: false,

    dateMenu: false,

    expirationDate: false,
    acceptBill: false,
    coupon: {
      code: "",
      percentOff: null,
      id: "",

      limit: null,
      endDate: "",
      applications: 0,
      status: "ACTIVE",
    },
    openLimit: false,
    formMode: "",
    isFormDialogOpen: false,
    isDeleteDialogOpen: false,
    loading: false,
    coupons: [],
    cols: [
      {
        text: "Código",
        value: "code",
        align: "center",
      },
      {
        text: "Porcentaje de descuento",
        value: "formattedPercentageOff",
        align: "center",
      },
      {
        text: "Límite de uso",
        value: "limit",
        align: "center",
      },
      {
        text: "Aplicaciones",
        value: "applications",
        align: "center",
      },
      {
        text: "Fecha de expiración",
        value: "endDate",
        align: "center",
      },
      {
        text: "Estado",
        value: "formattedStatus",
        align: "center",
      },
      {
        text: "Fecha de creación",
        value: "createdAt",
        align: "center",
      },
      {
        text: "Fecha de modificación",
        value: "updatedAt",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
      },
    ],
    validations: {
      code: validations.couponCodeRule({
        name: "código",
        required: true,
      }),
      percentOff: validations.numberMValidation({
        name: "porcentaje de descuento",
        required: true,
        min: 1,
        max: 100,
      }),
      limit: validations.numberMValidation({
        name: "limite de uso del cupón",
        required: true,
        min: 1,
      }),
      startDateRules: validations.requiredFValidation(
        "fecha de expiración del cupón"
      ),
    },
    formTitles: {
      CREATE: "Crear cupón",
      UPDATE: "Actualizar cupón",
    },
  }),
  async created() {
    await this.getCoupons();
  },
  computed: {
    ...mapState("user", ["user"]),
    todayDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);

      const day = ("0" + (date.getDate() + 1)).slice(-2);
      return `${year}-${month}-${day}`;
    },
    initialDateFormatted() {
      return this.formatDate(this.coupon.endDate.substring(0, 10));
    },
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    /**
     * @param { 'CREATE' | 'UPDATE' } mode
     */
    handleOpenForm(mode, item) {
      this.isFormDialogOpen = true;
      this.formMode = mode;
      if (item) {
        this.coupon = {
          code: item.code,
          percentOff: item.percentOff,
          id: item._id,
        };
      }
      console.log(this.coupon);
    },
    formatDate(date) {
      //
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    toggleLimit() {
      this.openLimit = this.acceptBill;
    },
    toggleExpirationDate() {
      this.openExpirationDate = this.expirationDate;
    },
    handleCloseDialog() {
      this.isFormDialogOpen = false;
      this.formMode = "";
      this.$refs.couponForm.reset();
    },
    handleSaveForm() {
      const mode = this.formMode;
      const functions = {
        CREATE: this.createCoupon,
        UPDATE: this.updateCoupon,
      };
      functions[mode] && functions[mode]();
    },
    handleOpenDeleteDialog(item) {
      this.isDeleteDialogOpen = true;
      this.coupon = {
        code: item.code,
        percentOff: item.percentOff,
        id: item._id,
      };
    },
    handleCloseDeleteDialog() {
      this.isDeleteDialogOpen = false;
      this.coupon = {
        code: "",
        percentOff: null,
        id: "",
      };
    },
    formatCouponData(coupon) {
      let formattedStatus;

      if (coupon.status === "ACTIVE") {
        formattedStatus = "Activo";
      } else {
        formattedStatus = "Expirado";
      }

      return {
        ...coupon,
        formattedStatus: formattedStatus,
        formattedPercentageOff: `${coupon.percentOff}%`,
        createdAt: moment(coupon.createdAt).format(DefaultDateFormat),
        updatedAt: moment(coupon.updatedAt).format(DefaultDateFormat),
      };
    },
    async getCoupons() {
      this.loading = true;
      const response = await this.axios.get(
        Endpoints.affiliationCouponsGet.replace(
          /:affiliationId/,
          this.affiliationId
        )
      );
      console.log("coupons", response);
      if (response.status === 200) {
        const { coupons } = response.data;

        coupons.forEach((coupon) => {
          if (coupon.endDate) {
            coupon.endDate = coupon.endDate.substring(0, 10);
          } else {
            coupon.endDate = "Sin expiración";
          }
          if (coupon.limit) {
            coupon.limit = coupon.limit;
          } else {
            coupon.limit = "Sin límite";
          }
          if (coupon.applications) {
            coupon.applications = coupon.applications;
          } else {
            coupon.applications = 0;
          }
          /*if (coupon.applications == coupon.limit) {
            coupon.status = "EXPIRED"
          } */
        });
        this.coupons = coupons;

        this.coupons = coupons.map((coupon) => this.formatCouponData(coupon));

        this.loading = false;
      }
      console.log("jo");
      console.log(this.coupons);
    },
    async createCoupon() {
      try {
        if (!this.$refs.couponForm.validate()) return;
        const payload = {
          coupon: {
            ...this.coupon,
            affiliation: this.affiliationId,
          },
        };
        const response = await this.axios.post(
          Endpoints.affiliationCoupons,
          payload
        );
        console.log("create coupon", response);
        if (response.status === 200) {
          const { coupon } = response.data;
          this.coupons.push(this.formatCouponData(coupon));
          this.handleCloseDialog();
        }
        await getCoupons();
      } catch (error) {
        let { message } = error.response.data.error
        this.show({
          color: 'error',
          text: message,
          time: 3500
        })
      }
    },
    async updateCoupon() {
      if (!this.$refs.couponForm.validate()) return;

      const { id, ...rest } = this.coupon;
      const payload = {
        coupon: {
          affiliation: this.affiliationId,
          ...rest,
        },
      };
      const response = await this.axios.put(
        Endpoints.affiliationCouponsUpdate.replace(/:couponId/, id),
        payload
      );
      if (response.status === 200) {
        const { coupon } = response.data;
        this.coupons = this.coupons.map((c) =>
          c._id === id ? this.formatCouponData(coupon) : c
        );
        this.handleCloseDialog();
        this.show({
          color: "primary",
          text: "El cupón se ha actualizado correctamente",
        });
      }
    },
    async deleteCoupon() {
      const payload = {
        coupon: {
          ...this.coupon,
          affiliation: this.affiliationId,
        },
      };
      const response = await this.axios.delete(Endpoints.affiliationCoupons, {
        data: payload,
      });
      console.log("delete coupon", response);
      if (response.status === 200) {
        this.coupons = this.coupons.filter(
          (coupon) => coupon._id !== this.coupon.id
        );
        this.handleCloseDeleteDialog();
        this.show({
          color: "primary",
          text: "Cupón eliminado correctamente",
        });
      }
    },
  },
};
</script>
