<template>
  <div class="level-row">
    <v-row class="ma-0 mb-2" align="center" justify="center" @mouseover="showActions = true"
      @mouseleave="showActions = false">
      <v-col cols="9">
        <v-row class="ma-0" align="center">
          <v-chip :color="level.status == 'ACTIVE' ? '#90caf9' : '#ef9a9a'" small class="rounded-lg font-weight-bold">
            {{ level.status == "ACTIVE" ? "Activo" : "Inactivo" }}
          </v-chip>
          <span class="wc-subtitle-2 ml-2">{{ level.name }}</span>
        </v-row>
        <v-row class="mx-0" justify="space-between">
          <span class="wc-caption-1" v-if="user.id != '66fb2d896646ca001cb93b3f'">
            <span class="font-weight-bold">Vendidos/Pendientes/Disponibles:</span>
            {{ formatNumber(soldAffiliationTypes(level)) }}/
            {{ formatNumber(level.pendingAffiliationTypes) }}/
            {{ formatNumber(level.totalQuantity - soldAffiliationTypes(level)) }}
          </span>
          <span class="wc-caption-1" v-else>
            <span class="font-weight-bold">Vendidos</span>
            {{ formatNumber(soldAffiliationTypes(level)) }}
          </span>

          <span class="wc-caption-1">
            <span class="font-weight-bold">Periodo: </span>
            {{ level.period == "YEAR" ? "Anual" : "Mensual" }}
          </span>

          <span class="wc-caption-1">
            <span class="font-weight-bold">Precio: </span>
            {{ level.formattedPrice }}
          </span>

          <span class="wc-caption-1">
            <span class="font-weight-bold">Detalle: </span>
            {{ level.details }}
          </span>
        </v-row>
      </v-col>

      <v-col cols="2">
        <v-fade-transition>
          <v-row class="ma-0" justify="space-around" v-if="showActions">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  v-if="user._id != '66fb2d896646ca001cb93b3f'"
                  icon 
                  :color="color.primaryVariant" 
                  v-bind="attrs" v-on="on" 
                  @click="handleAssignItems(level)"
                  :disabled="!user.permissions.Membership ||
                    !user.permissions.Membership.update"
                  >
                  <v-icon>mdi-ticket-confirmation-outline</v-icon>
                </v-btn>
              </template>
              <span>Asignar registros</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :color="color.primaryVariant" v-bind="attrs" v-on="on"
                  @click="handleUpdate({ item: level })" :disabled="!user.permissions.Membership ||
                    !user.permissions.Membership.update
                    ">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar nivel de membresía</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :color="color.primaryVariant" v-bind="attrs" v-on="on" @click="handleDelete(level)"
                  :disabled="!user.permissions.Membership ||
                    !user.permissions.Membership.update
                    ">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Eliminar nivel de membresía</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :color="color.primaryVariant" v-bind="attrs" v-on="on" @click="handleConstancy(level)"
                  :disabled="!user.permissions.Membership ||
                    !user.permissions.Membership.update
                    ">
                  <v-icon>mdi-card-text-outline</v-icon>
                </v-btn>
              </template>
              <span>Agregar Constancia</span>
            </v-tooltip>
          </v-row>
        </v-fade-transition>
      </v-col>

      <v-col cols="1">
        <v-row class="ma-0" justify="end">
          <v-btn icon @click="toggleExpand">
            <v-icon :color="color.primaryVariant" size="24">
              {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-expand-transition>
      <div v-show="isExpanded">
        <v-row justify="end" class="ma-0 pa-0" v-for="subLevel in level.subLevels" :key="subLevel._id">
          <v-col cols="11" class="sublevel-row">
            <v-row class="ma-0 mb-2" align="center" justify="center" @mouseover="showActions = true"
              @mouseleave="showActions = false">
              <v-col cols="9">
                <v-row class="ma-0" align="center">
                  <v-chip :color="subLevel.status == 'ACTIVE' ? '#90caf9' : '#ef9a9a'" small
                    class="rounded-lg font-weight-bold">
                    {{ subLevel.status == "ACTIVE" ? "Activo" : "Inactivo" }}
                  </v-chip>
                  <span class="wc-subtitle-2 ml-2">{{ subLevel.name }}</span>
                </v-row>
                <v-row class="mx-0" justify="space-between">
                  <span class="wc-caption-1">
                    <span class="font-weight-bold">Vendidos/Pendientes/Disponibles:</span>
                    {{ formatNumber(soldAffiliationTypes(subLevel)) }}/
                    {{ formatNumber(subLevel.pendingAffiliationTypes) }}/
                    {{ formatNumber(subLevel.totalQuantity - soldAffiliationTypes(subLevel)) }}
                  </span>

                  <span class="wc-caption-1">
                    <span class="font-weight-bold">Periodo: </span>
                    {{ subLevel.period == "YEAR" ? "Anual" : "Mensual" }}
                  </span>

                  <span class="wc-caption-1">
                    <span class="font-weight-bold">Precio: </span>
                    {{ subLevel.formattedPrice }}
                  </span>

                  <span class="wc-caption-1">
                    <span class="font-weight-bold">Detalle: </span>
                    {{ subLevel.details }}
                  </span>
                </v-row>
              </v-col>

              <v-col cols="2">
                <v-fade-transition>
                  <v-row class="ma-0" justify="space-around">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon :color="color.primaryVariant" v-bind="attrs" v-on="on"
                          @click="handleAssignItems(subLevel)" :disabled="!user.permissions.Membership ||
                            !user.permissions.Membership.update
                            ">
                          <v-icon>mdi-ticket-confirmation-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Asignar registros</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon :color="color.primaryVariant" v-bind="attrs" v-on="on"
                          @click="handleUpdate({ item: subLevel, disablePrice: level.inscriptionConfig.staticPriceForSub })"
                          :disabled="!user.permissions.Membership ||
                            !user.permissions.Membership.update
                            ">
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar nivel de membresía</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon :color="color.primaryVariant" v-bind="attrs" v-on="on"
                          @click="handleDelete(subLevel)" :disabled="!user.permissions.Membership ||
                            !user.permissions.Membership.update
                            ">
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar nivel de membresía</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon :color="color.primaryVariant" v-bind="attrs" v-on="on"
                          @click="handleConstancy(subLevel)" :disabled="!user.permissions.Membership ||
                            !user.permissions.Membership.update
                            ">
                          <v-icon>mdi-card-text-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Agregar Constancia</span>
                    </v-tooltip>
                  </v-row>
                </v-fade-transition>
              </v-col>

              <v-col cols="1">
                <v-row class="ma-0" justify="end">
                  <v-btn icon @click="toggleExpand">
                    <v-icon :color="color.primaryVariant" size="24">
                      {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
                    </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center" class="ma-0 pa-0 mt-2">
          <v-col cols="10" class="pa-0">
            <v-dialog v-model="openDialog" width="900">
              <template v-slot:activator="{ on, attrs }">
                <v-btn block color="#eaeef8" v-bind="attrs" v-on="on" :disabled="!user.permissions.Membership ||
                  !user.permissions.Membership.update
                  ">
                  <span class="font-weight-black text-primary-variant">
                    Configurar Nivel de Submembresía
                  </span>
                </v-btn>
              </template>
              <FormDialog :affiliationProp="level" :action="'create'" :showFiscalInfo="true" @close="handleClose" />
            </v-dialog>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { colors } from "@/constants/colors.js";
import FormDialog from "./FormDialog.vue";
import { mapState } from "vuex";
export default {
  name: "MembershipLevel",
  components: {
    FormDialog,
  },
  props: {
    level: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
      showActions: false,
      color: colors,
      openDialog: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    formatNumber(number) {
      return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    soldAffiliationTypes(item) {
      return item.soldAffiliationTypes ? item.soldAffiliationTypes : 0
    },
    handleConstancy(item) {
      this.$emit("constancy", item);
    },
    handleUpdate({ item, disablePrice = false }) {
      this.$emit("update", { item, disablePrice });
    },
    handleDelete(item) {
      this.$emit("delete", item);
    },
    handleAssignItems(item) {
      this.$emit("assign-items", item);
    },
    handleClose() {
      this.openDialog = false;
      this.$emit("reload");
    },
  },
};
</script>

<style>
.level-row {
  border-top: 2px solid #e0e0e0;
  border-bottom: 2px solid #e0e0e0;
  border-radius: 2px;
  padding: 16px;
}
.sublevel-row {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 4px;
  margin: 4px;
  background-color: #e0ecf0;
}
</style>